import React from 'react';
import Construcao from './assets/img/manutencao.png'
import Email from './assets/svg/email.svg'
import Instagram from './assets/svg/instagram.svg'
import Telefone from './assets/svg/telefone.svg'
import './App.css';

function App() {
  return (
    <div className="App">
       <h2>
          Site em Construção!
        </h2>
        <div className="Content">
          <img src={Construcao} className="App-logo" alt="Site em Construção" />
        </div>
        <footer>

          <div className="links">
            <ul>
                <li><a rel="opener" href="mailto:contato@seartemoveis.com.br"><img src={Email} alt="email" title="Email para contato" /></a></li>
                <li><a rel="opener" href="https://www.instagram.com/seartemoveis/"><img src={Instagram} alt="instagram" title="Instagram Oficial" /></a></li>
                <li><a rel="opener" href="tel:+554136211936"><img src={Telefone} alt="whatsapp" title="Telefone para contato" /></a></li>
            </ul>
          </div>

          <div className="container">
                <div className="content-footer">
                  ©️ Searte Móveis 2022
                </div>
            </div>

        </footer>
    </div>
  );
}

export default App;
